.loading-spinner {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 3rem;
  max-width: 12%;
  min-height: 3rem;
  max-height: 13%;
  z-index: 50;
  background-color: var(--loading-spinner-color);
  box-shadow: var(--big-box-shadow);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0rem;
}

@media (max-width: 480px) {
  .loading-spinner {
    max-width: 50%;
    max-height: 30%;
  }
}

.message {
  font-size: 1rem;
  padding: 1rem 0 0 0;
  text-align: center;
}